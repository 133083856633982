.sidebar {
  width: 358px; /* Adjust the width as per your requirements */
  background-color: #f1f1f1;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  padding: 50px 20px;
  background: linear-gradient(180deg, #e4eff9 0%, #cbd5f6 100%);
  box-shadow: 0px 0px 4px 0px rgba(0, 23, 106, 0.1);
  color: #00176a;
  font-family: "Space Grotesk", sans-serif;
  transform: translateX(-100%);
  transition: transform 0.25s ease-in-out;
  z-index: 2;
}

.sidebar[data-open="true"] {
  transform: translateX(0);
}

.sidebar-button {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 128px;
  height: 128px;
  cursor: pointer;
  background: linear-gradient(180deg, #e4eff9 0%, #cbd5f6 100%);
  clip-path: circle(48px at 16px 16px);
  box-shadow: 0px 0px 4px 0px rgba(0, 23, 106, 0.1);
  z-index: 1;
}

.sidebar-button--container {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
  padding: 12px;
}

.sidebar-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s;
}
.sidebar-background[data-open="true"] {
  opacity: 0.5;
  pointer-events: all;
}

.sidebar-header {
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-template-rows: 1fr 1fr;
  font-family: "Space Grotesk", sans-serif;
  column-gap: 16px;

  img {
    grid-row: 1 / -1;
    grid-column: 1;
    width: 100%;
  }

  h1,
  h2 {
    grid-column: 2;
    margin: 0;
    font-weight: 700;
  }

  h2 {
    grid-row: 1;
    grid-column: 2;
    font-size: 20px;
  }
  h1 {
    grid-row: 2;
    grid-column: 2;
    font-size: 24px;
  }
}

.sidebar-profile {
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    background: linear-gradient(180deg, #e3e2e9 0%, #5f8fb7 100%);
  }

  h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 48px;
  }

  a {
    color: rgba(0, 23, 106, 0.5);
    text-decoration-line: underline;
  }
}

.sidebar-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;

  .sidebar-link {
    display: flex;
    align-items: center;
    line-height: 40px;
    border-radius: 16px;
    width: calc(100% + 16px);
    margin-left: -8px;
    padding-left: 8px;

    img {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }

    &:hover {
      background: #c9d2f380;
    }

    &[data-selected="true"] {
      background: #c9d2f3;
    }
  }

  // special, emphasized button
  .sidebar--checkin {
    background: linear-gradient(90deg, #8877dc, #6851dc);
    color: white;
    justify-content: center;
    transition:
      background 0.2s,
      transform 0.2s;

    &:hover,
    &[data-selected="true"] {
      background: linear-gradient(90deg, #634dd1, #4428cc);
      transform: translateY(-1px);
    }

    &.sidebar--withdraw {
      background: linear-gradient(90deg, #db7676, #db5151);

      &:hover,
      &[data-selected="true"] {
        background: linear-gradient(90deg, #d14d4d, #cc2929);
      }
    }
  }
}

.sidebar-nav--bottom {
  position: absolute;
  width: calc(100% - 40px);
  bottom: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #00176a;
  font-weight: bold;
}

.sidebar-view-qr:hover {
  color: #00176a;
  cursor: pointer;
}

// small screen (ie, iPhone SE), and needs a more condensed sidebar
@media screen and (max-height: 720px) {
  .sidebar {
    padding: 20px;
  }

  .sidebar-profile {
    margin: 16px 0;

    img {
      width: 96px;
      height: 96px;
    }
  }

  .sidebar-nav--bottom {
    position: relative;
  }
}

@media screen and (min-width: 586px) {
  .sidebar {
    transform: translateX(0);
  }
  .sidebar-background {
    display: none;
  }
  .sidebar-button {
    display: none;
  }
}

@media screen and (min-width: 586px) {
  .sidebar {
    display: block;
  }
}