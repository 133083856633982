.profile-page--wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  font-family: "Space Grotesk", sans-serif;
}

.profile-page {
  position: relative;
  width: calc(100% - 40px);
  max-width: 1120px;

  .profile--title {
    margin-top: 0;
    margin-bottom: 48px;
    color: #00176a;
  }
}

.profile--main {
  background: #fefefe;
  display: flex;
  justify-content: center;
  border-radius: 30px;
}

.profile--main-content {
  position: relative;
  width: 100%;
  max-width: 842px;
  box-sizing: border-box;
  padding: 20px;

  color: #00176abf;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 16px 64px;

  .profile--header {
    grid-column: 1 / -1;

    display: flex;
    flex-direction: column;
    align-items: center;

    .profile--picture {
      width: 128px;
      height: 128px;
      border-radius: 50%;
      background: linear-gradient(180deg, #e3e2e9 0%, #5f8fb7 100%);
    }

    button {
      cursor: pointer;
      display: block;
      border: none;
      background: #8877dc;
      color: white;
      border-radius: 10px;
      padding: 12px;
      margin-top: 10px;
    }
  }

  .profile--name {
    font-size: 20px;

    // name
    h1 {
      display: inline-block;
      font-weight: 700;
      font-size: 28px;
    }

    // pronouns
    h2 {
      color: #00176a80;
      font-weight: 400;
      font-size: 28px;
    }
  }

  .profile--food-group {
    img {
      height: 1em;
    }

    span {
      white-space: nowrap;

      &.cherry {
        color: #e13d3d;
        background: #fceded;
      }
      &.peach {
        color: #f79486;
        background: #feeeec;
      }
      &.blueberry {
        color: #47719f;
        background: #d5e0ec;
      }
      &.strawberry {
        color: #e8525e;
        background: #fadbde;
      }
    }
  }

  .profile--team {
    font-size: 22px;
    margin-bottom: 16px;
  }

  .profile--bio {
    margin-bottom: 16px;
  }

  .profile--univeristy {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 32px 1fr;

    * {
      font-size: 22px;
    }

    .profile--university-school-label,
    .profile--university-program-label {
      font-weight: 400;
    }
  }
}

.profile--links {
  overflow: hidden;
  width: calc(100% + 16px);
  margin-left: -8px;
  ul {
    list-style-type: none;
  }
}

.profile--main-content .profile--social-link {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 40px;
  border-radius: 16px;
  width: 100%;
  margin-left: 0px;
  padding-left: 8px;
  font-weight: 700;
  text-decoration: none;
  color: rgba(0, 23, 106, 0.7490196078);
  cursor: pointer;

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }

  .arrow {
    margin-right: 16px;
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
  }

  span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background: #c9d2f380;
    cursor: pointer;
    .arrow {
      transform: rotate(45deg);
    }
  }
}

@media screen and (min-width: 586px) {
  .profile-page--wrapper {
    padding-top: 40px;
  }

  .profile-page {
    .profile--title {
      margin-top: 96px;
    }
  }

  .profile--main-content {
    grid-template-columns: 1fr 1fr;

    .profile--header {
      display: block;
      button {
        float: right;
      }
    }
  }

  .profile--name {
    h2 {
      float: right;
    }
  }
}
