@import "../../Styles/variables.scss";

.faq {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.faq-question {
  display: flex;
  background: $translucent-white;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  &:hover {
    cursor: pointer;
    opacity: 0.85;
  }

  .body {
    font-family: $font;
    font-weight: 400;
    font-size: 16px;
    padding-right: 8px;
  }
}

.faq-answer {
  .open {
    opacity: 1;
    padding: 16px;
    max-height: 185px;
    transition:
      opacity ease-in 0.4s,
      max-height ease-in 0.2s;
    font-family: $font;
    font-weight: 400;
    font-size: 16px;
  }
  .closed {
    opacity: 0;
    max-height: 0px;
    transition: max-height ease-out 0.1s;
  }
}

.sign {
  width: 12px;
  height: 12px;
}

.not-listed {
  margin-left: 30px;
  margin-right: 30px;
  a {
    color: white;
    font-weight: bold;
    &:hover {
      opacity: 0.8;
    }
  }
}

@media screen and (min-width: 768px) {
  .faq {
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 40px;
  }

  .faq-question {
    display: flex;
    background: $translucent-white;
    border-radius: 16px;
    align-items: center;
    justify-content: space-between;
    padding: 48px;
    .body {
      font-family: $font;
      font-weight: 400;
      font-size: 24px;
    }
  }

  .faq-answer {
    .open {
      padding: 48px;

      font-size: 24px;
    }
  }

  .sign {
    width: 24px;
    height: 24px;
  }

  .not-listed {
    margin-left: 100px;
    margin-right: 100px;
    font-size: 24px;
    a {
      text-decoration: none;
    }
  }
}
