@import "../../../Styles/variables.scss";

.home-container {
  width: 100%;
  overflow: hidden;
  z-index: 0;
  margin-top: 150px;
  font-family: $font;
  color: white;
  justify-content: center;
  .components {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card-components {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .why-join-codejam-header {
    text-align: center;
    font-size: 40px;
    font-family: $font;
    margin-bottom: 90px;
    font-weight: 700;
  }
  .faq-header {
    text-align: center;
    font-size: 40px;
    font-family: $font;
    margin-top: 70px;
    margin-bottom: 56px;
    font-weight: 700;
  }
  .whatiscodejam-container {
    display: flex;
    margin: auto;
    flex-direction: column;
    margin-bottom: 80px;
    margin-top: 80px;
    width: 343px;

    .text-container {
      .heading {
        font-family: $font;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        margin-top: 40px;
        margin-bottom: 16px;
        margin-left: 16px;
        margin-right: 16px;
      }

      .introduction-text {
        font-family: $font;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-left: 16px;
        margin-right: 16px;
        #bold-codejam {
          font-family: $font;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
  .illustration-container {
    text-align: center;
    justify-content: center;
    .illustration {
      width: 343px;
      height: 226px;
    }
  }
  .want-to-sponsor {
    text-align: center;
    a {
      color: white;
    }
  }
}

@media screen and (min-width: 768px) {
  .home-container {
    .card-components {
      flex-direction: row;
    }
    .whatiscodejam-container {
      width: 90%;
      // margin-top: 108px;
      text-align: left;
      align-content: center;
      flex-direction: row;
      .illustration-container {
        display: flex;
        .illustration {
          margin: auto;
          width: 403px;
          height: 300px;
        }
      }
      .text-container {
        // margin-top: 108px;
        .heading {
          line-height: 45px;
          font-size: 40px;
          margin-bottom: 20px;
        }
        .introduction-text {
          font-size: 24px;
          line-height: 40px;
          #bold-codejam {
            font-family: $font;
            font-size: 24px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .whatiscodejam-container {
    width: 90%;
    .illustration-container {
      .illustration {
        width: 538px;
        height: 400px;
      }
    }
  }
}
