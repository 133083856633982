.add-member-modal-window {
  position: absolute;
  z-index: 100;

  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  width: 600px;
  height: 250px;
  padding: 20px 20px;

  align-items: flex-start;
  align-content: flex-start;
  gap: 26px 0px;
  flex-wrap: wrap;
  border-radius: 30px;
  background: #f9f9f9;
  box-shadow: 0px 4px 50px 0px rgba(0, 23, 106, 0.2);

  .topSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    flex: 1 0 0;
    margin-left: 30px;
    .headerText {
      margin-top: 20px;
      width: 320px;
      color: rgba(0, 23, 106, 0.75);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .subtext {
      color: rgba(0, 23, 106, 0.75);
      font-size: 17px;
      font-weight: 400;
    }
  }

  .code-box {
    display: flex;
    width: 70%;
    padding: 10px 10px;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    align-items: center;
    border-radius: 16px;
    border: 1px solid rgba(136, 119, 220, 0.25);
    cursor: pointer;
  }

  .code-box:hover {
    border: 1px solid rgba(136, 119, 220, 0.75);
  }

  .copied-text {
    line-height: 48px;
  }
}
