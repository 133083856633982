@import "../../Styles/variables.scss";
.card-container {
  background-color: $translucent-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  justify-content: space-between;
  box-shadow: 4px 4px 15px rgba(71, 58, 136, 0.08);
  width: 343px;
  // height: 200px;
  height: 250px;
  margin: 10px;
  opacity: 1;
  .text {
    margin: auto;
    text-align: center;
    font-family: $font;
    .number {
      color: $codejam_blue;
      font-weight: 700;
      font-size: 16px;
    }
    .title {
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: 500;
    }
    .subheading {
      font-size: 16px;
      font-weight: 400;
      margin-left: 16px;
      margin-right: 16px;
      line-height: 24px;
    }
    .asterisk {
      color: white;
      font-weight: 400;
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 1080px) {
  .card-container {
    flex-direction: row;
    width: 468px;
    // height: 300px;
    height: 400px;
    padding: 30px;
    line-height: 24px;
    .text {
      margin: 0;
      .number {
        font-size: 27px;
      }
      .title {
        font-size: 27px;
      }
      .subheading {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 24px;
        font-weight: 400;
        line-height: 40px;
      }
      .asterisk {
        font-size: 18px;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .card-container {
    flex-direction: row;
    width: 625px;
    // height: 400px;
    height: 500px;
    line-height: 24px;
    .text {
      .number {
        margin-bottom: 50px;
        font-size: 32px;
      }
      .title {
        margin-top: 8px;
        margin-bottom: 45px;
        font-size: 32px;
      }
      .subheading {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 50px;
        font-size: 27px;
        font-weight: 400;
        line-height: 40px;
      }
      .asterisk {
        font-size: 18px;
      }
    }
  }
}
