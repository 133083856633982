@import "../../Styles/variables.scss";
.tabcard-container {
  background-color: $translucent-white;
  flex-direction: column;
  display: flex;
  align-items: center;
  box-shadow: 4px 4px 15px rgba(71, 58, 136, 0.08);
  width: 350px;
  height: 250px;
  border-radius: 8px;
  .text {
    color: white;
    text-align: center;
    margin: auto;
    font-size: 25px;
    line-height: 50px;
  }
}

@media screen and (min-width: 768px) {
  .tabcard-container {
    border-radius: 16px;
    width: 500px;
    height: 350px;
    .text {
      font-size: 40px;
      line-height: 75px;
    }
  }
}
