@import "../../Styles/variables.scss";

.whole-container {
  flex-direction: column;
  display: flex;
  align-items: center;
  max-height: 120px;
  margin-bottom: 10px;
  .textInput {
    font-family: $font;
    width: 100%;
    max-width: 330px;
    border: 2.5px solid transparent;
    border-radius: 8px;
    font-size: 24px;
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    height: 50.5px;

    background: #3c5381;
    color: white;

    // different states
    &:hover {
      border-color: #f7f9fc80;
    }
    &:disabled {
      color: #f7f9fc40;
    }
    // no outline when it's disabled and hovered
    &:disabled:hover {
      border-color: transparent;
    }
    &:focus {
      border-color: #f7f9fc;
      outline: none;
    }
    &.textInput--error {
      border-color: #f98b88;
    }
  }

  .button {
    padding-top: 10px;
    padding-bottom: 10px;
    border-width: 0;
    background: linear-gradient(112.27deg, #99c8f0, #8877dc, #99c8f0);
    border-radius: 8px;
    color: white;
    font-family: $font;
    font-size: 24px;
    width: 100%;
    max-width: 330px;
    background-position: center;
    transition: 0.4s;
    background-size: 200%;
    background-position: left;

    cursor: pointer;
    &:hover {
      background-position: right;
    }
  }

  .textInput:disabled ~ .button {
    opacity: 0.5;
    cursor: default;
  }
}

.button-textinput {
  .error {
    color: #ff8c85;
    font-family: "Space Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 31.5px; /* 131.25% */
  }
}

//Tablet
@media screen and (min-width: 768px) {
  .whole-container {
    max-height: 70px;
    flex-direction: row;
    max-height: 60px;
    .textInput {
      width: 100%;
      margin-bottom: 0px;
      margin-right: 10px;
    }
    .button {
      width: 165px;
    }
  }
}
