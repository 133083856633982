@import "../../../Styles/variables.scss";

.home-container {
  width: 100%;
  overflow: hidden;
  font-family: $font;
  color: white;
  justify-content: center;
  align-items: center;

  .home-wrapper {
    .components {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .greeting-container {
        min-height: 400px;
        justify-content: center;
        flex-flow: column;
        display: flex;
        margin-top: 16px;
        margin-bottom: 16px;
        .header {
          text-align: center;
        }
      }
    }
  }
}
.img-container {
  display: contents;
  .img {
    width: 300px;
    margin-bottom: 16px;
    align-self: center;
  }
}

.team-cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;
  grid-gap: 24px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .home-container {
    .home-wrapper {
      .components {
        .greeting-container {
          margin-bottom: 90px;
        }
      }
    }
  }

  .team-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .img-container {
    .img {
      width: 400px;
      margin-bottom: 40px;
      align-self: center;
    }
  }
}
@media screen and (min-width: 1300px) {
  .team-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 30px;
    margin-left: 100px;
    margin-right: 100px;
  }

  .greeting-container {
    min-height: 900px;
    width: auto;
    margin-bottom: 90px;
    justify-content: center;
    flex-flow: column;
    display: flex;
  }
  .img-container {
    .img {
      margin-bottom: 40px;
      align-self: center;
    }
  }
}
