@import "../../../Styles/variables.scss";

.login-explainer {
  padding-top: 40px;
}

.login-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: $linear2;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  .title-container {
    text-align: left;
    width: 754px;
    height: 100%;
    margin-top: 225px;
    font-family: $font;
    // font-weight: 700px;
    // font-size: 48px;
    color: white;
    padding-bottom: 70px;
    align-items: left;
    .email-container {
      padding-top: 5%;
      // font-weight: 400px;
      .email-header {
        width: 482px;
        align-items: left;
        font-family: $font;
        // font-size: 24px;
        color: $grey;
        padding-bottom: 2%;
      }
      .input-box {
        position: relative;
        width: 100%;
        height: 66px;
        margin-bottom: 15px;
        .password-box-success {
          width: inherit;
          height: inherit;
          border-radius: 16px;
          background-color: $input-field-background;
          border: none;
          outline: none;
          font-family: $font;
          // font-size: 28px;
          padding-left: 20px;
          padding-right: 75px;
          color: $white;
          z-index: 0;
        }
        .password-box-failure {
          width: inherit;
          height: inherit;
          border-radius: 16px;
          background-color: $input-field-background;
          border-color: red;
          outline: none;
          font-family: $font;
          // font-size: 28px;
          padding-left: 20px;
          color: $white;
          z-index: 0;
          padding-right: 75px;
        }
        .email-box-success {
          width: inherit;
          height: inherit;
          border-radius: 16px;
          background-color: $input-field-background;
          border: none;
          outline: none;
          font-family: $font;
          // font-size: 28px;
          padding-left: 20px;
          padding-right: 20px;
          color: $white;
          z-index: 0;
        }
        .email-box-failure {
          width: inherit;
          height: inherit;
          border-radius: 16px;
          background-color: $input-field-background;
          border-color: red;
          outline: none;
          font-family: $font;
          // font-size: 28px;
          padding-left: 20px;
          padding-right: 20px;
          color: $white;
          z-index: 0;
        }
      }

      .input-box::placeholder {
        color: $input-field-placeholder;
      }
      .input-box img {
        width: 45px;
        position: absolute;
        top: 50%;
        z-index: 50;
        right: 20px;
        transform: translateY(-45%);
        // font-size: 15px;
        cursor: pointer;
      }
      .error-messages-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 15px;
        .error-image {
          width: 50px;
          height: 20px;
          padding-right: 15px;
        }
        .error-messages {
          // font-size: 24px;
          color: $red;
        }
      }
      .password-header {
        display: flex;
        flex-direction: row;
        font-family: $font;
        // font-size: 24px;
        color: $grey;
        padding-bottom: 2%;
        justify-content: space-between;
        .forgot-password {
          // font-weight: 600;
          color: $white;
          text-decoration: none;
        }
      }
      .forgot-password {
        // font-weight: 600;
        color: $white;
        text-decoration: none;
      }
      .login-button {
        width: 100%;
        height: 96px;
        margin-top: 30px;
        border-width: 0;
        background-image: linear-gradient(112.27deg, #99c8f0, #8877dc, #99c8f0);
        border-radius: 16px;
        color: white;
        padding: 5px;
        font-family: $font;
        // font-size: 32px;
        transition: 0.4s;
        background-size: 200%;
        background-position: left;
        cursor: pointer;
        &:hover {
          background-position: right;
        }
      }
      .forgot-password-wrapper {
        padding-top: 20px;
        text-align: center;
        // font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .login-container {
    .title-container {
      width: 70%;
      // font-size: 30px;
      // padding-top: 175px;
      .email-container {
        .input-box {
          margin-bottom: 10px;
          .password-box-success {
            // font-size: 22px;
          }
          .password-box-failure {
            // font-size: 22px;
          }
          .email-box-success {
            // font-size: 22px;
          }
          .email-box-failure {
            // font-size: 22px;
          }
        }
        .error-messages-container {
          .error-image {
            width: 35px;
            height: 20px;
          }
          .error-messages {
            // font-size: 22px;
          }
        }
        .password-header {
          .forgot-password {
            // font-size: 22px;
          }
        }
        .login-button {
          height: 80px;
          // font-size: 28px;
        }
        .forgot-password-wrapper {
          // font-size: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .login-container {
    .title-container {
      width: 85%;
      // font-size: 22px;
      .email-container {
        .email-header {
          // font-size: 18px;
        }
        .input-box {
          margin-bottom: 10px;
          height: 50px;
          .email-box-success {
            // font-size: 16px;
            padding-left: 15px;
          }
          .email-box-failure {
            // font-size: 16px;
            padding-left: 15px;
          }
          .password-box-success {
            // font-size: 16px;
            padding-left: 15px;
            padding-right: 50px;
          }
          .password-box-failure {
            // font-size: 16px;
            padding-left: 15px;
            padding-right: 50px;
          }
        }
        .error-messages-container {
          .error-image {
            width: 28px;
            height: 15px;
          }
          .error-messages {
            // font-size: 16px;
          }
        }
        .password-header {
          // font-size: 18px;
          .forgot-password {
            padding-top: 4px;
            // font-size: 12px;
          }
        }
        .input-box img {
          width: 25px;
          top: 45%;
          right: 15px;
        }
        .login-button {
          margin-top: 15px;
          height: 70px;
          // font-size: 24px;
        }
        .forgot-password-wrapper {
          // font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .login-container {
    .title-container {
      // font-size: 18px;
      .email-container {
        .email-header {
          // font-size: 16px;
        }
        .input-box {
          height: 48px;
          .email-box-success {
            // font-size: 14px;
            padding-left: 10px;
            padding-right: 10px;
          }
          .email-box-failure {
            // font-size: 14px;
            padding-left: 10px;
            padding-right: 10px;
          }
          .password-box-success {
            // font-size: 14px;
            padding-left: 10px;
            padding-right: 35px;
          }
          .password-box-failure {
            // font-size: 14px;
            padding-left: 10px;
            padding-right: 35px;
          }
        }
        .error-messages-container {
          .error-image {
            width: 25px;
            padding-right: 10px;
          }
          .error-messages {
            // font-size: 14px;
          }
        }
        .input-box img {
          width: 20px;
          top: 45%;
          right: 12px;
        }
        .password-header {
          // font-size: 16px;
          .forgot-password {
            // font-size: 10px;
          }
        }
        .login-button {
          margin-top: 5px;
          height: 60px;
          // font-size: 22px;
        }
        .forgot-password-wrapper {
          // font-size: 12px;
        }
      }
    }
  }
}
