.team-page {
  padding: 40px 20px; //changed
  gap: 12px; //changed
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  font-family: Space Grotesk;

  // animate filter: 'blur(5px)'to take 5s
  transition: filter 0.25s ease-in-out;

  a {
    text-decoration: none;
  }

}

.team-container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 10px 10px; //changed
  flex-direction: column;
  // align-items: flex-end;
  gap: 12px; //changed
  align-self: stretch;
  border-radius: 30px; //changed
  background: #fefefe;
  color: #00176a;

  .team-header {
    flex-direction: row;
    display: flex;
    justify-content: space-between;

    .team-name {
      text-align: center;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .team-members {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-self: stretch;

    .team-member {
      background-color: #eeedf3;
      border-radius: 20px;
      min-height: 100px;
      position: relative;
      width: 300px;

      .profile {
        background: linear-gradient(
          180deg,
          rgb(153, 200, 240) 0%,
          rgb(136, 119, 220) 100%
        );
        border-radius: 58px;
        height: 70px;
        left: 12px;
        position: absolute;
        top: 12px;
        width: 70px;
      }

      .member-info {
        margin-top: 12px;
        width: 190px;
        margin-left: 94px;
        letter-spacing: 0;
        line-height: 22px;

        .name {
          font-size: 15px;
          font-weight: 500;
        }

        .pronouns {
          font-size: 13px;
          font-weight: 400;
        }

        .university {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  .add-member {
    align-items: center;
    border: 2px dashed;
    border-color: #8877dc;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    height: 100px;
    position: relative;
    width: 300px;

    .plus-icon {
      height: 30px;
      position: relative;
      width: 30px;
    }
  }
  .add-member:hover {
    cursor: pointer;
    opacity: 0.85;
    background-color: #f0edff;
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-self: stretch;
    justify-content: center;

    .description {
      width: 300px;
      font-size: 14px;
      text-align: center;
    }

    .devpost {
      background-color: #eeedf3;
      border-radius: 16px;
      height: 47px;
      position: relative;
      width: 300px;

      .devpostLogo {
        left: 8px !important;
        position: absolute !important;
        top: 8px !important;
      }

      .devpostText {
        font-size: 18px;
        left: 60px;
        letter-spacing: 0;
        line-height: 48px;
        position: absolute;
        white-space: nowrap;
      }

      .devpostArrow {
        height: 49px;
        right: 0px;
        position: absolute;
        top: -1px;
        width: 49px;
        transform: rotate(0deg);
        transition: transform 0.3s ease; /* Add a transition for smooth animation */
      }
    }

    .devpost:hover {
      .devpostArrow {
        transform: rotate(45deg);
      }
      cursor: pointer;
    }
  }
}

@media (min-width: 1036px) {
  .team-page {
    padding: 30px 50px;
    gap: 12px;

    h1 {
      width: 900px;
      align-self: center;
    }
  }
  .team-container {
    max-width: 900px;
    padding: 50px 50px;
    gap: 24px;
    border-radius: 30px;

    .team-name {
      text-align: start;
      font-size: 34px;
      font-weight: 500;
    }

    .team-members {
      justify-content: space-between;
      gap: 36px;

      .team-member {
        border-radius: 20px;
        height: 172px;
        width: 380px;

        &:hover {
          cursor: pointer;
          opacity: 0.85;
          background-color: #f0edff;
        }

        .profile {
          border-radius: 58px;
          height: 116px;
          left: 36px;
          top: 28px;
          width: 116px;
        }

        .member-info {
          width: 214px;
          margin-left: 174px;
          margin-top: 32px;

          .name {
            font-size: 18px;
          }

          .pronouns {
            font-size: 16px;
          }

          .university {
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }

      .add-member {
        height: 172px;
        width: 380px;

        .plus-icon {
          height: 56px;
          width: 56px;
        }
      }
    }

    .footer {
      gap: 36px;
      justify-content: space-between;

      .description {
        width: 380px;
        font-size: 17px;
      }

      .devpost {
        background-color: #eeedf3;
        border-radius: 16px;
        height: 47px;
        position: relative;
        width: 380px;
      }
    }
  }
}

.profile--links {
  ul {
    list-style-type: none;
  }
}

.profile--social-link {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 40px;
  border-radius: 16px;
  width: 100%; /* Adjust width to fit the container */
  margin-left: -8px;
  padding-left: 8px;
  font-weight: 700;
  text-decoration: none;
  color: rgba(0, 23, 106, 0.7490196078);
  cursor: pointer;
  overflow: hidden; /* Add this to prevent horizontal overflow */

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }

  &:hover {
    background: #c9d2f380;
    width: 80%;
    .arrow {
      transform: rotate(45deg);
    }
  }
}

// @media (min-width: 1429px) {
//     .team-page {
//         padding: 40px 0px;

//         h1 {
//             width: 1136px;
//             align-self: center;
//         }

//         .team-container {
//             max-width: 1136px;
//             padding: 96px 139px;
//             gap: 36px;
//             border-radius: 30px;

//             .team-name {
//                 text-align: start;
//                 font-size: 34px;
//                 font-weight: 500;
//             }

//             .team-members {
//                 justify-content: space-between;
//                 gap: 36px;

//                 .team-member {
//                     border-radius: 20px;
//                     height: 172px;
//                     width: 407px;

//                     .profile {
//                         border-radius: 58px;
//                         height: 116px;
//                         left: 36px;
//                         top: 28px;
//                         width: 116px;
//                     }

//                     .member-info {
//                         width: 214px;
//                         margin-left: 174px;
//                         margin-top: 32px;

//                         .name {
//                             font-size: 18px;
//                         }

//                         .pronouns {
//                             font-size: 16px;
//                         }

//                         .university {
//                             font-size: 16px;
//                         }
//                     }
//                 }

//                 .add-member {
//                     height: 172px;
//                     width: 407px;

//                     .plus-icon {
//                         height: 56px;
//                         width: 56px;
//                       }
//                 }
//             }

//             .footer {
//                 gap: 36px;
//                 justify-content: space-between;

//                 .description {
//                     width: 407px;
//                     font-size: 17px;
//                 }

//                 .devpost {
//                     background-color: #eeedf3;
//                     border-radius: 16px;
//                     height: 47px;
//                     position: relative;
//                     width: 407px;
//                 }
//             }
//         }
//     }
// }
