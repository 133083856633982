@import "../../Styles/variables.scss";
.mailing-list-wrapper {
  display: flex;
  flex-direction: column;
  .mailing-robot {
    width: 343px;
    height: 314.37px;
    margin: auto;
  }
  .not-image-part {
    .mailing-list-caption {
      text-align: center;
      .caption-header {
        margin-top: 20px;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 7.63px;
      }
      .caption-subheading {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        width: 226px;
        margin: auto;
        margin-bottom: 40px;
      }
    }
    .social-section {
      margin-top: 40.37px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .social-icons-wrapper {
        margin-top: 16px;
        display: flex;
        width: 200px;
        flex-direction: row;
        padding: 0px;
        gap: 40px;
        .social-icons {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .mailing-list-wrapper {
    display: flex;
    flex-direction: row-reverse;
    // .mailing-robot {
    //     width: 483px;
    //     height: 442.69px;
    // }
    .not-image-part {
      padding-top: 80px;
      margin-left: 120px;
      .mailing-list-caption {
        .caption-header {
          text-align: left;
          font-size: 36px;
          line-height: 71.46px;
          margin-bottom: 24px;
        }
        .caption-subheading {
          font-size: 24px;
          line-height: 40px;
          width: 550px;
          text-align: left;
          margin-bottom: 40px;
        }
      }
      .social-section {
        margin-top: 80px;
        align-items: flex-start;
        .social-icons-wrapper {
          margin-top: 24px;
        }
      }
    }
  }
}
@media screen and (min-width: 1204px) {
  .mailing-list-wrapper {
    .mailing-robot {
      width: 483px;
      height: 442.69px;
    }
    .not-image-part {
      padding-top: 80px;
      .mailing-list-caption {
        .caption-header {
          font-size: 56px;
          line-height: 71.46px;
          margin-bottom: 24px;
        }
        .caption-subheading {
          font-size: 24px;
          line-height: 40px;
          width: 754px;
          margin-bottom: 40px;
        }
      }
      .social-section {
        margin-top: 80px;
        .social-icons-wrapper {
          margin-top: 24px;
        }
      }
    }
  }
}
