@import "../../Styles/variables.scss";

.button-container {
  border-width: 0;
  background-image: linear-gradient(112.27deg, #99c8f0, #8877dc, #99c8f0);
  border-radius: 8px;
  color: white;
  padding: 5px;
  font-family: $font;
  font-size: 16px;
  height: 56px;
  width: 285px;
  transition: 0.4s;
  background-size: 200%;
  background-position: left;
  cursor: pointer;
  &:hover {
    background-position: right;
  }
}

@media screen and (min-width: 768px) {
  .button-container {
    width: 367px;
    height: 96px;
    font-size: 32px;
  }
}
