@import "../../Styles/variables.scss";
.container {
  text-decoration: none;
  color: white;
  background-color: $translucent-white;
  border-radius: 8px;
  height: 400;
  width: 325px;
  display: flex;
  flex-direction: column;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  .faceBorder {
    margin-top: 20px;
    height: 175px;
    width: 175px;
    background-image: $linear2;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    .face {
      height: 160px;
      width: 160px;
      border-radius: 50%;
    }
  }
  .text {
    margin-top: 40px;
    text-align: center;
    font-family: $font;
    .name {
      margin: 0 0 24px 0;
      padding: 0;
      font-size: 32px;
      font-weight: 700;
    }
    .subheading {
      margin: 0 0 16px 0;
      padding: 0;
      font-size: 24px;
      font-weight: 500;
    }
  }
}

//smallest is iPhone SE, this is iPhone XR
@media screen and (min-width: 414px) {
  .container {
    width: 367px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    height: 480px;
    .faceBorder {
      margin-top: 40px;
    }
  }
}
