@import "../../Styles/variables.scss";

.sponsor-container {
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;

  .sponsor-card {
    background-color: $translucent-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    justify-content: space-between;
    width: 343px;
    margin: auto;
    opacity: 0.8;
  }

  .sponsor-heading {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;
    color: white;
  }
  .terabyte-container {
    .terabyte {
      max-height: 100px;
      max-width: 330px;
      .a {
        margin: auto;
        margin-bottom: 10px;
      }
    }
  }
  .gigabyte-container {
    margin-top: 20px;
    .gigabyte {
      max-height: 80px;
      max-width: 250px;
      .a {
        margin: auto;
      }
    }
  }
  .megabyte-container {
    margin-top: 20px;
    padding-bottom: 20px;
    .megabyte {
      max-height: 80px;
      max-width: 250px;
      .a {
        margin: auto;
      }
    }
  }
  .supporter-container {
    margin-top: 20px;
    padding-bottom: 20px;
    .supporter {
      max-height: 50px;
      max-width: 250px;
      .a {
        margin: auto;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}

@media screen and (min-width: 414px) {
  .sponsor-container {
    .sponsor-card {
      width: 400px;
    }
  }
}

@media screen and (min-width: 768px) {
  .sponsor-container {
    .sponsor-card {
      background-color: transparent;
      width: 100%;
      margin-bottom: 50px;
    }
    .sponsor-heading {
      display: none;
    }
    .terabyte-container {
      .terabyte {
        max-width: 700px;
        max-height: 500px;
      }
    }
    .gigabyte-container {
      flex-direction: row;
      width: 1000px;
      .gigabyte {
        max-width: 260px;
        max-height: 500px;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .sponsor-container {
    .megabyte-container {
      .megabyte {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
