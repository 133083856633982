@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");
$font: "Space Grotesk";
$text-color: white;
$highlighted-text: linear-gradient(112.27deg, #99c8f0 0%, #8877dc 100%);
$linear2: linear-gradient(112.27deg, #99c8f0 0%, #8877dc 100%);
$linear1: linear-gradient(114.26deg, #473a88 0%, #d0eaff 100%);
$linear_background: linear-gradient(180deg, #181237 0%, #094679 100%);
$linear_blobs: linear-gradient(
  114.26deg,
  rgba(71, 58, 136, 0.1) 0%,
  #5f8fb7 100%
);
$black: #000000;
$white: #ffffff;
$red: #ff8c85;
$purple-blue: #00176a;
$codejam_blue: #5f8fb7;
$blue: #094679;
$purple: #473a88;
$translucent-white: rgba(255, 255, 255, 0.1);
$grey: #adb5bd;
$input-field-background: rgba(95, 143, 183, 0.33);
$input-field-placeholder: rgba(248, 249, 252, 0.25);

.header {
  font-family: $font;
  font-weight: 700;
  font-size: 56px;
  color: $text-color;
}

.body {
  font-family: $font;
  font-weight: 400;
  font-size: 16px;
  .link {
    color: $white;
  }
}

.small-body {
  padding: 0 25px;
  font-family: $font;
  font-weight: 400;
  font-size: 14px;
}
.highlight-text {
  background: linear-gradient(112.27deg, #99c8f0 0%, #8877dc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media screen and (min-width: 768px) {
  .header {
    font-family: $font;
    font-weight: 700;
    font-size: 112px;
    color: $text-color;
  }

  .body-header {
    font-family: $font;
    font-weight: 700;
    font-size: 56px;
    color: $text-color;
  }

  .body {
    font-family: $font;
    font-weight: 400;
    font-size: 24px;
  }
}
