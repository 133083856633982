@import "../../Styles/variables.scss";

.sidebar-show {
  position: fixed;
  // display:static;
  flex-direction: column;
  align-content: center;
  background-color: $codejam_blue;
  height: 100vh;
  opacity: 100%;
  z-index: 21;
  width: 60%;
  padding-top: 50px;
  padding-left: 16px;

  .x-button {
    height: 24px;
    align-self: flex-start;
    margin-bottom: 60px;
  }

  .body {
    align-items: center;
    display: flex;
    margin-bottom: 24px;
    // font-size: 24px;
    // font-weight: 700;
    padding-left: 16px;
    width: fit-content;
    .unselected {
      color: white;
      text-decoration: none;
    }

    .selected {
      color: white;
      padding-bottom: 3px;
      border-bottom: 1.5px solid #fff;
      text-decoration: none;
    }
  }
  .lang-switcher-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 16px;
  }
}
.sidebar-hide {
  display: none;
}

.navbar {
  background-color: rgba(25, 23, 71, 1);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  position: fixed;
  top: 0;
  margin: 0%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 20;

  .menu-bar {
    margin-left: 16px;
    width: 24px;
  }
  .navbar-header {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    .navbar-logo {
      height: 56px;
      align-self: center;
      padding-right: 24px;
      display: flex;
    }
    .body-header {
      display: none;
      width: 0;
    }
    &:hover {
      opacity: 80%;
    }
  }

  .menu-items {
    display: none;
    width: 0;
  }
}

.navbar.active {
  opacity: 100%;
  box-shadow: 0px 4px 8px 10px rgba(0, 0, 0, 0.08);
}

@media screen and (min-width: 1000px) {
  .navbar {
    padding-left: 60px;
    padding-right: 60px;
    .menu-bar {
      display: none;
    }

    .menu-items {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;

      .body {
        align-items: center;
        display: flex;
        margin-left: 16px;
        margin-right: 16px;
        .unselected {
          color: white;
          text-decoration: none;
        }

        .selected {
          color: white;
          padding-bottom: 3px;
          border-bottom: 1.5px solid #fff;
          text-decoration: none;
        }
      }

      .body:hover {
        opacity: 80%;
      }
    }

    .navbar-header .navbar-logo {
      height: 80px;
    }
  }
}
@media screen and (min-width: 1240px) {
  .navbar {
    padding-left: 100px;
    padding-right: 100px;
    .navbar-header .body-header {
      visibility: visible;
      width: auto;
      display: flex;
      // font-size: 48px;
      align-self: center;
    }
  }
}

.a-nodecoration {
  color: white;
  text-decoration: none;
}
