@import "../Dashboard.scss";

.team-page2 {
  padding: 40px 20px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  font-family: Space Grotesk;

  .team-container {
    margin: 0 auto;
    display: flex;
    padding: 10px 10px;
    flex-direction: column;
    gap: 12px;
    align-self: stretch;
    border-radius: 30px; //changed
    background: #fefefe;
    color: #00176a;

    .team-input-box {
      flex-direction: column;
      display: flex;
      justify-content: space-between;

      .team-custom-input {
        border-radius: 16px;
        background: #eeedf3;
        width: 80vw;
        margin-top: 10px;
        padding: 0px 20px;
        height: 47px;
        border: none;
        color: black;
      }

      .team-bio-input {
        height: 100px;
        padding-top: 20px;
        resize: none;
      }

      .team-checkbox-container {
        display: flex;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
      }

      .team-checkbox {
        display: none; /* Hide the default checkbox */
      }

      .team-checkbox-label {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        position: relative;
        background: red; /* Default to red */
      }

      /* When the checkbox is checked, change background to green */
      .team-checkbox:checked + .team-checkbox-label {
        background: green;
      }

      /* Adding checkmark or "X" when the checkbox state changes */
      .team-checkbox:checked + .team-checkbox-label::before {
        content: "✓"; /* Unicode checkmark */
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
      }

      .team-checkbox-label-text {
        margin-left: 10px;
        margin-top: 10px;
      }

      .team-checkbox:not(:checked) + .team-checkbox-label::before {
        content: "✕"; /* Unicode X */
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
      }
    }
  }
  .join-button {
    min-width: 100px;
  }
  .submit-button {
    display: flex;
    margin-top: 10px;
    padding: 12px 14px;
    justify-content: center;
    align-self: flex-end;
    width: 100%;
    border-radius: 10px;
    background: #8877dc;
    color: white;
  }
}

@media screen and (min-width: 586px) {
  .team-page2 {
    .join-input-container {
      flex-direction: row;
      display: flex;
      .join-button {
        margin-top: 10px;
        margin-left: 10px;
        padding: 12px 14px;
        height: 47px;
        width: 10%;
      }
    }
    width: 100%;
    .team-container {
      width: 100%;
      .team-input-box {
        .team-custom-input {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1036px) {
  .team-page2 {
    .submit-button {
      margin-top: 10px;
      // width: 10%;
    }
  }
}
