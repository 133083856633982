.db--wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  font-family: "Space Grotesk", sans-serif;
}

.db-page {
  position: relative;
  width: calc(100% - 40px);
  max-width: 1120px;

  .db--title {
    margin-top: 0;
    margin-bottom: 48px;
    color: #00176a;
  }
}

.db--main {
  background: #fefefe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 30px;
}

@media screen and (min-width: 586px) {
  .db--wrapper {
    padding-top: 40px;
  }

  .db-page {
    .db--title {
      margin-top: 96px;
    }
  }
}
