@import "../../../Styles/variables.scss";

.home-container {
  width: 100%;
  overflow: hidden;
  font-family: $font;
  color: white;
  justify-content: center;
  .components {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img {
      margin-top: 20px;
      width: 300px;
      margin-bottom: 16px;
      align-self: center;
    }
  }

  .title {
    margin-top: 20px;
    font-size: 40px;
    font-weight: 700;
    line-height: 15px;
  }
  .sponsor {
    margin-top: 20px;
    font-size: 30px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 7.63px;
  }
  .grid-container {
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: auto;

    .sponsor-container {
      padding-top: 40px;
      display: grid;
      align-items: center;
      grid-column-gap: 40px;
      justify-content: center;
      grid-template-columns: max-content max-content max-content;
      .terabyte {
        height: 100px;
        margin-bottom: 20px;
      }
      .gigabyte {
        height: 80px;
        margin-bottom: 20px;
      }
      .megabyte {
        height: 60px;
        margin-bottom: 50px;
      }
      .supporter {
        height: 40px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .home-container {
    width: 100%;
    overflow: hidden;
    font-family: $font;
    color: white;
    justify-content: center;
    .components {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .img {
        width: 300px;
        margin-bottom: 16px;
        align-self: center;
      }
    }

    .title {
      margin-top: 20px;
      font-size: 70px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 7.63px;
    }
    .sponsor {
      margin-top: 20px;
      font-size: 30px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 7.63px;
    }
    .grid-container {
      justify-content: center;
      align-items: center;
      align-content: center;
      margin: auto;

      .sponsor-container {
        padding-top: 40px;
        display: grid;
        align-items: center;
        grid-column-gap: 40px;
        justify-content: center;
        grid-template-columns: max-content max-content max-content;
        .terabyte {
          height: 100px;
          margin-bottom: 20px;
        }
        .gigabyte {
          height: 80px;
          margin-bottom: 20px;
        }
        .megabyte {
          height: 60px;
          margin-bottom: 50px;
        }
        .supporter {
          height: 40px;
        }
      }
    }
  }
}
