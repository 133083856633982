.profile-edit--text-input {
  border-radius: 16px;
  background: #eeedf3;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px 20px;
  height: 47px;
  border: none;
  color: #00176a;
}
.profile-edit--textarea {
  resize: none;
  height: 94px;
  outline: none;
  font-family: "Space Grotesk";
  font-size: 25px;
}

.profile--main-content.edit {
  grid-template-columns: 1fr;
}

.profile-edit--form {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;

  label {
    font-size: 22px;
    white-space: nowrap;
    align-self: center;
    font-weight: 700;
    justify-self: end;

    &.profile-edit--full-width {
      grid-column: 1 / -1;
      justify-self: start;
    }
  }
}

.profile-edit--upload-icon-container {
  display: inline-flex;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 50%;
  position: absolute;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform: translate(-75%);
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
  }
}

.ep-upload--picture {
  cursor: pointer;
}

.ep-save--button {
  cursor: pointer;
  display: block;
  border: none;
  background: #8877dc;
  color: white;
  border-radius: 10px;
  padding: 12px;
  margin-top: 10px;
}
