.dashboard-content {
  a {
    text-decoration: none;
  }

  a:link,
  a:visited {
    color: #00176a;
  }

  a.button:link {
    color: white;
  }

  a:hover {
    color: indigo;
  }

  a.button:hover {
    color: white;
  }
}

.required {
  color: red;
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.center {
  margin: auto;
}

.button {
  color: white;
  display: flex;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #8877dc;
  text-decoration: none;
  cursor: pointer;
}

.button:hover {
  opacity: 0.8;
}

.closeButton {
  position: absolute;
  right: 30px;
  top: 40px;
}

.closeButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

.leave-button {
  background: #ff9f9f;
  align-self: flex-end;
}

.small-button {
  background: #8877dc;
  align-self: center;
}

.dashboard-content {
  margin-top: 80px;
}

@media screen and (min-width: 586px) {
  .dashboard-content {
    margin-left: 358px;
    margin-top: 0px;
  }
}
