@import "../../Styles/variables.scss";
.land-ack-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  margin: 0 auto;
  flex-direction: column;
  .land-ack-wrapper {
    text-align: center;

    .title {
      margin-bottom: 8px;
      line-height: 32px;
      align-self: stretch;
    }
    .land-body {
      line-height: 24px;
      align-self: stretch;
    }
  }
}
.copyrights {
  flex-direction: row-reverse;
  display: flex;
  margin: 0 16px;
  margin-top: 16px;
  font-size: 10px;
}

@media screen and (min-width: 1024px) {
  .land-ack-container {
    font-size: 16px;
    .land-ack-wrapper {
      width: 754px;
    }
  }
}
