/* Remove browser defaults */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Style App.js wrapper */
.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Tab Container */
.Tabs {
  width: 100%;
  height: auto;
  min-height: 400px;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: rgba(255, 255, 255, 0.5);

  border-radius: 0rem;
  .outlet {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  @media (max-width: 769px) {
    padding: 2rem 0;
  }
}

.event-info {
  flex-direction: column;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
}

/* Tab Navigation */
ul.nav {
  width: 60%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2rem;
  padding-left: 0px;
  @media (max-width: 768px) {
    overflow-x: auto;
    margin-left: 5%;
    margin-right: 0;
    width: 95%;
  }
}

ul.nav li {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}

ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}

ul.nav li.active {
  cursor: pointer;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  font-weight: 500;
}

/* Tab Content Styles */
.TabContent {
  font-size: 2rem;
  text-align: center;
}

@media screen and (min-width: 768px) {
  * {
    .event-info {
      flex-direction: row;
    }
  }
}
