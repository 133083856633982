.landing-container {
  position: relative;
  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
      width: 343px;
      height: 278px;
    }
  }
  .text-container {
    width: inherit;
    .text-wrapper {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      .top-header {
        margin-top: 30px;
        margin-bottom: 8px;
      }
      .middle-header {
        height: min-content;
        margin-bottom: 8px;
      }
      .bottom-header {
        margin-top: 20px;
        margin-bottom: 30px;
      }
      .social-icons-wrapper {
        margin-top: 16px;
        display: flex;
        width: 200px;
        flex-direction: row;
        padding: 0px;
        gap: 40px;
        .social-icons {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .landing-container {
    .img-container {
      img {
        width: 450px;
        height: 364.7px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .landing-container {
    display: flex;
    height: 445.5px;
    padding-top: 80px;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    // width: 90%;
    .img-container {
      position: absolute;
      right: -50px;
      img {
        width: 500px;
        height: 445.5px;
      }
    }
    .text-container {
      position: absolute;
      left: 100px;
      .text-wrapper {
        align-items: flex-start;
        .title {
          font-size: 90px;
        }
      }
    }
  }
}

@media screen and (min-width: 1240px) {
  .landing-container {
    margin-left: 50px;
    margin-top: 100px;
    .img-container {
      right: 50px;
      img {
        width: 700px;
        height: 567px;
      }
    }
  }
}
