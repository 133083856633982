@import "../Dashboard.scss";

.withdraw-page {
  padding: 40px 20px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  font-family: Space Grotesk;

  .withdraw-container {
    margin: 0 auto;
    display: flex;
    padding: 60px 60px;
    flex-direction: column;
    gap: 12px;
    align-self: stretch;
    border-radius: 30px; //changed
    background: #fefefe;
    color: #00176a;

    .withdraw-input-box {
      flex-direction: column;
      display: flex;
      justify-content: space-between;

      .withdraw-custom-input {
        border-radius: 16px;
        background: #eeedf3;
        width: 80vw;
        margin-top: 10px;
        padding: 0px 20px;
        height: 47px;
        border: none;
        color: black;
      }

      .input-disabled {
        // background: gray;
        color: lightgray;
      }

      .withdraw-checkbox-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
      }

      .checkbox-input {
        padding: 8px;
      }

      // replace with the checkbox image
      .withdraw-checkbox {
        visibility: hidden;
        width: 0;
      }

      .withdraw-checkbox-label {
        margin-left: 16px;
        position: relative;
        cursor: pointer;
        display: flex;
        margin-top: -14px;
        align-items: center;
      }

      .withdraw-checkbox--icon {
        width: 32px;
      }

      .withdraw-checkbox-label-text {
        margin-left: 10px;
      }
    }
  }

  .submit-button {
    display: flex;
    margin-top: 10px;
    padding: 12px 14px;
    justify-content: center;
    align-self: flex-end;
    width: 100%;
    border-radius: 10px;
    background: #8877dc;
    color: white;
    border: none;
  }
  .submit-button:disabled {
    background: #888;
    color: black;
    pointer-events: none;
  }
}

@media screen and (min-width: 586px) {
  .checkin-page {
    .checkin-input-container {
      flex-direction: row;
      display: flex;
      .submit-button {
        margin-top: 10px;
        margin-left: 10px;
        padding: 12px 14px;
        height: 47px;
        width: 10%;
      }
    }
    width: 100%;
    .withdraw-container {
      width: 100%;
      .checkin-input-box {
        .checkin-custom-input {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1036px) {
  .withdraw-page {
    .withdraw-container {
      .withdraw-input-box {
        .withdraw-checkbox-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          margin-left: 20px;
          margin-bottom: 20px;
          gap: 100px;
        }
      }
    }
    .submit-button {
      margin-top: 10px;
      // width: 10%;
    }
  }
}
