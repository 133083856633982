@import "../../Styles/variables.scss";

.infocard-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 50px;
  .text {
    text-align: left;
    font-family: $font;
    color: #ffffff;
    .edition {
      font-weight: 400;
      font-size: 18px;
      line-height: 48px;
    }
    .codejam {
      font-weight: 700;
      font-size: 45px;
      line-height: 50px;
    }
    .date {
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 50px;
      line-height: 50px;
    }
  }
}

@media screen and (min-width: 768px) {
}
