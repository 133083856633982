@import "./variables.scss";

.body-header {
  color: $text-color;
}

.h1 {
  font-size: 96px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 300;
  line-height: 122.5px;
}
.h2 {
  font-size: 60px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 300;
  line-height: 70.02px;
  letter-spacing: -1.5px;
}
.h3 {
  font-size: 48px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 600;
  line-height: 56.02px;
  letter-spacing: -1.5px;
}
.h4 {
  font-size: 34px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 600;
  line-height: 39.68px;
}
.h5 {
  font-size: 24px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 600;
  line-height: 28.01px;
}
.h6 {
  font-size: 20px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 600;
  line-height: 23.34px;
}
.subtitle1 {
  font-size: 16px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 400;
  line-height: 18.67px;
}
.subtitle2 {
  font-size: 14px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  line-height: 21.98px;
  letter-spacing: 0.10000000149011612px;
}
.label {
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.42px;
  text-align: left;
}
.body1 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}
.body2 {
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.86px;
  text-align: left;
}
.caption {
  font-family: "Space Grotesk", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.92px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}
.overline {
  font-family: "Space Grotesk", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 31.92px;
  letter-spacing: 1px;
  text-align: left;
}
.link {
  font-weight: 600;
  cursor: pointer;
  color: #638eb5;
}
