@import "../../../../Styles/variables.scss";
// Define styles for the dropdown container
.dropdown-container {
  select {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    //background-color: $input-field-background; // Use your desired background color
    border: none;
    outline: none;
    font-family: $font;
    font-size: 18px; // Adjust the font size as needed
    padding: 5px;
    background-color: #303b62;
    color: white;
    margin-bottom: 30px;
    vertical-align: middle; // Align the text vertically in the middle
  }

  // Customize the appearance of the dropdown arrow
  select::-ms-expand {
    display: none; // Hide the default arrow in IE/Edge
  }
}

// Apply additional styling based on screen size
@media screen and (max-width: 850px) {
  .dropdown-container {
    select {
      font-size: 16px; // Adjust font size for smaller screens
    }
  }
}

@media screen and (max-width: 600px) {
  .dropdown-container {
    select {
      font-size: 14px; // Further adjust font size for smaller screens
    }
  }
}
