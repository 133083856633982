.team-gallery-page {
  padding: 40px 20px; //changed
  gap: 12px; //changed
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  font-family: Space Grotesk;

  // animate filter: 'blur(5px)'to take 5s
  transition: filter 0.25s ease-in-out;
}

.team-gallery-container {
  margin: 0 auto;
  display: flex;
  padding: 10px 10px;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
  border-radius: 30px; //changed
  background: #fefefe;
  color: #00176a;
}

#icon {
  gap: 10px;
}

.search-box {
  display: flex;
  align-items: center;
  gap: 30px;
  align-self: stretch;
}

.table-style {
  padding: 10px 15px;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
  background: #eeedf3;
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;
}

.table-row {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  height: 32px;
}

.custom-search {
  color: black;
  height: 35px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid #eeedf3;
  //width: 724px;
  flex-grow: 1;
  background-image: url(../../../../src/Resources/search-normal.svg);
  background-repeat: no-repeat;
  background-position-y: 11px;
  background-position-x: 14px;
  text-indent: 33px;
  margin: auto;
}

.table-style th {
  // padding-right: 110px; /* Add padding to the table header cells */
  padding-top: 5px;
}

/* Optionally, you can style the filter arrow icon separately if needed */
.table-style th img {
  margin-left: 5px; /* Add margin to the filter arrow icon */
}

td.name {
  padding-left: 10px;
}
/*td.looking {
  top: 5px;
  position: relative;
  right: 360px;
}
td.members {
  position: relative;
  right: 180px;
}*/

.title-team-browser {
  color: #00176a;
  font-family: Space Grotesk;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 100% */
  padding-bottom: 69px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fefefe;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px; //chang
}

.dropdown-content div {
  color: #00176a;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown .filterOption div:hover {
  background-color: #e7e7e7;
}

.show {
  display: block;
}

.unstyled-button {
  background: none;
  border: 0;
  position: relative;
  top: 7px;
  left: 3px;
}

.portraits {
  position: relative;
  margin-right: -10px;
  top: 7px;
  left: 2px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 50%;
}

.table-grid {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
}

.table-row a {
  align-content: center;
}
.table-row a:hover {
  font-weight: 400;
}

td.looking {
  text-align: center;
}

td.looking img {
  width: 24px;
  padding-top: 6px;
}
