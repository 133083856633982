@import "./variables.scss";
@import "./Typography.scss";

.app-container {
  width: 100%;
  overflow-x: hidden;
  .app-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 600px;
    // height: 100vh;
    justify-content: center;
  }
  .footer-section {
    margin-top: 60px;
    color: $white;
    font-family: $font;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .land-ack-section {
      width: 100%;
      margin-top: 20px;
      height: auto;
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: $blue;
    }
  }
}

@media screen and (min-width: 768px) {
  .app-container {
    .app-wrapper {
      min-height: 380px;
      .footer-section {
        align-items: center;
        justify-content: center;
        display: flex;
        margin-left: -16px; // to be removed for final version
        flex-direction: column;
      }
    }
  }
}
